import React from "react"
import BlogListItem from "./bloglist-item"
import TextHeader from "./TextHeader"
import { Link } from "gatsby"
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import Matrix from "../images/matrix.svg"

const BloggListSection = ({ data, link, className }) => {
    return (
        <div>
            <Matrix className="h-full w-full border-b p-5 fill-purple-700  " />
            <div className={`grid grid-cols-2 border-b ${!link && "grid-cols-1"}`}>
                <TextHeader title="Blogg" subtitle="En samling ofullständiga tankar." className="p-5" />
                {link && (<Link className="border-l hover:bg-gray-100 flex  space-x-2 items-center justify-center group" to="/blogg">

                    <span className="text-gray-400 group-hover:text-gray-900">Se alla inlägg</span>
                    <ArrowLongRightIcon className=" stroke-gray-400   h-5 w-5 group-hover:stroke-gray-900" />
                </Link>)}
            </div>
            <div className={`grid  ${className} `}>
                {data.allMdx.edges.map(({ node }) => (
                    <BlogListItem node={node} />
                ))}
            </div>
        </div>
    )
}

export default BloggListSection