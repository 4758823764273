import { Link } from "gatsby"
import React from "react"
import PostHeader from './PostHeader'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'


const BlogListItem = ({ node }) => {
    return (
        <Link to={node.frontmatter.path} className="group hover:pl-5 pr-5 rounded-sm duration-700 hover:bg-gray-100 px-5 border-b">
            <div className="text-bloghalka-main  flex items-center justify-between py-5  ">
                <div className="">
                    <p className="text-lg font-medium">{node.frontmatter.title}</p>
                    <PostHeader date={node.frontmatter.date} timeToRead={node.timeToRead} />
                </div>


                <div className="p-2 group">
                    <ArrowLongRightIcon className=" stroke-gray-400   h-5 w-5 group-hover:stroke-gray-900" />
                </div>

            </div>
        </Link >
    )
}

export default BlogListItem