import React from "react"

const TextHeader = ({ title, subtitle, className }) => {
    return (
        <div className={`${className}`}>
            <h1 className="blog-xl-text">{title}</h1>
            <p className="blog-sm-text mt-2">{subtitle}</p>
        </div>
    )
}


export default TextHeader;
