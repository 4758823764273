import React from 'react';

export function dateConvert(date) {
    return date
        .replace('Jan', 'Januari')
        .replace('Feb', 'Februari')
        .replace('Mar', 'Mars')
        .replace('Apr', 'April')
        .replace('May', 'Maj')
        .replace('Jun', 'Juni')
        .replace('Jul', 'Juli')
        .replace('Aug', 'Augusti')
        .replace('Sep', 'September')
        .replace('Oct', 'Oktober')
        .replace('Nov', 'November')
        .replace('Dec', 'December')
}


const PostHeader = ({ date, timeToRead, className }) => {
    return (
        <div className={className}>

            <div className="">
                <div className="flex space-x-2">
                    <span className="flex items-center">


                        <span className="blog-sm-text">{dateConvert(date)}</span>
                        {/* <MinusIcon className="mx-1 h-5 w-5 fill-gray-400" />

                        <span className="text-sm">{timeToRead + " min"}</span> */}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PostHeader;